import React, { Component } from 'react'

interface Props {
  title: string
  location: Location
  modifiers?: any
}

interface State {
  localModifiers: Array<any>
  mobileClass: string
}

class Navibar extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      mobileClass: 'mobile-active',
      localModifiers: [],
    }

    this.toClassesFromParent.bind(this)
    this.onClickToggleMenu.bind(this)
  }

  onClickToggleMenu = (event: MouseEvent) => {
    // event.preventDefault()
    const { localModifiers, mobileClass } = this.state

    if (localModifiers.includes(mobileClass)) {
      const index = localModifiers.indexOf(mobileClass)
      localModifiers.splice(index, 1)
    } else {
      localModifiers.push('mobile-active')
    }

    this.setState({ localModifiers })
  }

  toClassesFromParent = (modifiers: Array<any>) => {
    if (!modifiers) {
      return ''
    }

    const { localModifiers } = this.state

    const modifiersJoin: Array<any> = [...localModifiers, ...modifiers]

    const modifierClasses = modifiersJoin.length
      ? modifiersJoin.reduce((result: string, modifier: string) => {
          result = `${result} ${modifier}`
          return result
        }, '')
      : ''

    return modifierClasses
  }

  render() {
    const { modifiers } = this.props
    const { localModifiers, mobileClass } = this.state

    return (
      <header
        className={`page_header ls ${this.toClassesFromParent(modifiers)}`}
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-8 col-xl-2 text-left">
              <a rel="canonical" href="/" className="logo">
                <img src="/images/logo.png" alt="Ada Aesthetics logo" />
              </a>
            </div>
            <div className="col-xl-8 col-1 text-center">
              <nav className="top-nav">
                <ul className="nav sf-menu">
                  <li>
                    <a
                      rel="canonical"
                      href="/"
                      onClick={this.onClickToggleMenu}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://booksy.com/en-gb/instant-experiences/widget/61001?instant_experiences_enabled=true&ig_ix=true&is_fb=1&fbclid=IwAR2p-lXjX6nvc-eD8ZQzHfOI9gXvf1O9J-HESKw62yYQR_VpYMfaU5QS4qo"
                    >
                      Book now
                    </a>
                  </li>
                  <li>
                    <a href="/treatments" onClick={this.onClickToggleMenu}>
                      Treatments
                    </a>
                  </li>
                  <li>
                    <a href="/about" onClick={this.onClickToggleMenu}>
                      About
                    </a>
                  </li>
                  <li>
                    <a href="/portfolio" onClick={this.onClickToggleMenu}>
                      Portfolio
                    </a>
                  </li>
                  <li>
                    <a href="/contact" onClick={this.onClickToggleMenu}>
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-1 col-xl-2">
              <div className="header-included">
                <div className="dropdown text-center">
                  <i className="fa fa-phone"></i>{' '}
                  <a href="tel:07478705252">07478705252</a>
                  <a href="https://booksy.com/en-gb/instant-experiences/widget/61001?instant_experiences_enabled=true&ig_ix=true&is_fb=1&fbclid=IwAR2p-lXjX6nvc-eD8ZQzHfOI9gXvf1O9J-HESKw62yYQR_VpYMfaU5QS4qo">
                    <button
                      className="btn btn-outline-maincolor
                btn-sm make-appointment-top"
                    >
                      book now
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          className={`toggle_menu ${
            localModifiers.includes(mobileClass) ? mobileClass : ''
          }`}
          onClick={this.onClickToggleMenu}
        />
      </header>
    )
  }
}

export default Navibar
