import React from 'react'

const Copyright: React.FC = () => (
  <section className="page_copyright ls">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-12 text-center">
          <div className="fw-divider-space hidden-below-xl mt-65" />
          <div className="fw-divider-space hidden-above-xl mt-20" />
          <p>
            &copy; <span className="copyright_year">{' 2023'}</span> Ada Aesthetics LTD, Inc. All rights reserved
          </p>
          <div className="fw-divider-space hidden-below-xl mt-65" />
          <div className="fw-divider-space hidden-above-xl mt-20" />
        </div>
      </div>
    </div>
  </section>
)

export default Copyright
