import React from 'react'

const Footer: React.FC = () => (
  <footer className="page_footer overflow-hidden ls s-pt-10 mt-10 s-overlay s-parallax">
    <div className="container">
      <div className="row">
        <div className="fw-divider-space hidden-below-xl mt-140" />
        <div className="fw-divider-space hidden-below-lg d-xl-none mt-110" />
        <div className="fw-divider-space hidden-above-lg mt-50" />
        <div className="col-12 text-center" data-animation="fadeInUp">
          <div className="widget widget_mailchimp">
            <h2 className="special-heading">Newsletter</h2>

            <p>
              We promise not to spam you with our newsletters, but don&apos;t
              forget that subscribing will give discounts on all of your future
              purchases.
            </p>

            <p style={{ fontSize: '12px' }}>
              By clicking subscribe button, you agree to receive product
              information <br />
              and offers from us.
            </p>

            <form
              className="signup"
              method="post"
              netlify-noneypot="bot-field"
              data-netlify="true"
              name="newsletter"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="newsletter" />
              <input type="hidden" name="_gotcha" />
              <label htmlFor="mailchimp_email1">
                <span className="screen-reader-text">Subscribe:</span>
              </label>
              <i className="flaticon-envelope" />
              <input
                name="email"
                type="email"
                className="form-control"
                placeholder="Your Email"
              />

              <button type="submit" className="search-submit">
                <i className="fa fa-pencil" />
              </button>
              <i className="response" />
            </form>
          </div>
        </div>
        <div className="fw-divider-space hidden-below-xl mt-140"></div>
        <div className="fw-divider-space hidden-below-lg d-xl-none mt-110"></div>
        <div className="fw-divider-space hidden-above-lg mt-50"></div>
      </div>
    </div>
  </footer>
)

export default Footer
