import React, { useState, useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'
import { Modal, Button } from 'react-bootstrap'

import Navibar from '../navibar/navibar'
import Sticky from '../navibar/Sticky'
import Footer from '../footer/footer'
import Copyright from '../copyrights'
import Sitemap from '../footer/Sitemap'
import FBChat from '../FBChat'
import { siteMetadata } from '../../../gatsby-config'

import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/gatstrap.scss'
import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'

interface Props {
  children?: React.ReactNode
  location: Location
}

const Layout: React.FC<Props> = ({ children, location }: Props) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    const location = window.localStorage.getItem('LocationConfirmation')

    if (!location) {
      setShow(true)
      window.localStorage.setItem('LocationConfirmation', true)
    }
  }, [show])

  return (
    <div>
      <div
        className="modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="search_modal"
        id="search_modal"
      >
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        id="messages_modal"
      >
        <div className="fw-messages-wrap ls p-normal" />
      </div>

      <div id="canvas">
        <div id="box_wrapper">
          <Sticky sides={{ top: 0 }}>
            <Navibar title={siteMetadata.title} location={location} />
          </Sticky>
          {children}
          <FBChat />
          <Footer />
          <Sitemap />
          <Copyright />
        </div>
      </div>
      <CookieConsent buttonWrapperClasses="cookie-button">
        We use cookies and similar technologies to help personalise content,
        tailor and measure ads, and provide a better experience.
      </CookieConsent>
    </div>
  )
}

export default Layout
