import React from 'react'
import SocialButtons from './socialbuttons'

const Sitemap: React.FC = () => (
  <footer className="page_footer sitemap overflow-hidden ds ms s-pt-xl-150 s-pt-lg-100 s-pt-50 s-pb-10 s-pb-lg-100 s-pb-xl-100 c-mb-30 mt-10 c-gutter-60">
    <div className="container overflow-hidden">
      <div className="row">
        <div className="divider-20 d-none d-xl-block"></div>

        <div className="col-lg-4">
          <div className="widget">
            <h3 className="text-center color-white">Treatments</h3>
            <div className="text-center">
              <a className="abs-link" title="lip fillers" href="/lip-fillers">
                Lip Fillers
              </a>
            </div>
            <div className="text-center">
              <a
                className="abs-link"
                title="Dermal Fillers"
                href="/dermal-fillers"
              >
                Dermal Fillers
              </a>
            </div>
            <div className="text-center">
              <a
                className="abs-link"
                title="Tear Trough Filler"
                href="/tear-trough-filer"
              >
                Tear Trough Filler
              </a>
            </div>
            <div className="text-center">
              <a
                className="abs-link"
                title="Cheek Fillers"
                href="/cheek-fillers"
              >
                Cheek Fillers
              </a>
            </div>
            <div className="text-center">
              <a
                className="abs-link"
                title="Anti-Wrinkle Injections anti wrinkle (btx)"
                href="/anti-wrinkle-injections-btx"
              >
                Anti-Wrinkle Injections
              </a>
            </div>
            <div className="text-center">
              <a
                className="abs-link"
                title="Lash lift"
                href="/lash-lift-and-tint"
              >
                Lash lift
              </a>
            </div>
            <div className="text-center">
              <a
                className="abs-link"
                title="Brow Lamination And Tint"
                href="/eyebrows-lamination-and-tint"
              >
                Brow Lamination And Tint
              </a>
            </div>
            <div className="text-center">
              <a
                className="abs-link"
                title=" Russian flat lips"
                href="/russian-lips"
              >
                Russian flat lips
              </a>
            </div>
            <div className="text-center">
              <a
                className="abs-link"
                title="Fat-Dissolving – Aqualyx"
                href="/fat-dissolving"
              >
                Fat-Dissolving – Aqualyx
              </a>
            </div>
            <div className="text-center">
              <a
                className="abs-link"
                title="Luxury Dermaplaning"
                href="/dermaplaning"
              >
                Luxury Dermaplaning
              </a>
            </div>
            <div className="text-center">
              <a
                className="abs-link"
                title="Microblading And Nanoblading"
                href="/microblading"
              >
                Microblading And Nanoblading
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="widget">
            <h3 className="text-center color-white">Contact</h3>
            <div className="text-center">
              <span className="icon-styled">
                <i className="flaticon-envelope color-main"></i>
              </span>{' '}
              <a href="mailto:contact@adaaesthetics.uk">
                contact@adaaesthetics.uk
              </a>
            </div>
            <div className="text-center">
              <span className="icon-styled">
                <i className="flaticon-headphones color-main"></i>
              </span>{' '}
              07478705252
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="widget">
            <h3 className="text-center color-white">SOCIAL MEDIA</h3>
            <div className="text-center">
              <SocialButtons />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Sitemap
