import React from 'react'
import CustomerChat from 'react-customer-chat'

const FBChat = () => (
  <CustomerChat
    pageId="105147281399372"
    attribute={{
      // eslint-disable-next-line @typescript-eslint/camelcase
      logged_out_greeting:
        "Hi! We're here to answer any questions you may have.",
      // eslint-disable-next-line @typescript-eslint/camelcase
      logged_in_greeting:
        "Hi! We're here to answer any questions you may have.",
      // eslint-disable-next-line @typescript-eslint/camelcase
      greeting_dialog_display: 'fade',
    }}
  />
)

export default FBChat
