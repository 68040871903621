import React from 'react'

const SocialButtons: React.FC = () => (
  <div className="widget widget_social_buttons">
    <a
      href="https://www.facebook.com/adaaestheticsuk"
      className="fa fa-facebook border-icon rounded-icon"
      title="facebook Ada Aesthetics"
      target="_blank"
      rel="noreferrer"
    ></a>
    <a
      href="https://www.instagram.com/ada_aesthetics_uk"
      className="fa fa-instagram border-icon rounded-icon"
      title="instagram"
      target="_blank"
      rel="noreferrer"
    ></a>
    <a
      href="https://www.tiktok.com/@ada_aesthetics_uk?_t=8WRtuaEZJav&_r=1"
      className="fa fa-clock-o border-icon rounded-icon"
      title="tiktok"
      target="_blank"
      rel="noreferrer"
    ></a>
  </div>
)

export default SocialButtons
